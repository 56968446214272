{
  "navbar": {
    "about": "About me",
    "contact": "Contact me"
  },
  "home": {
    "hello": "Hi there, I'm",
    "job-assistant": "Assistant engineer",
    "job-technician": "Laboratory technician",
    "desired-job-intro": "I'm looking for an internship from March 28th to August 12th 2022 in the field of",
    "desired-job": "Business development"
  },
  "header": {
    "fr-flag-alt": "French flag",
    "fr-flag-disabled-alt": "French flag disabled",
    "fr-flag-title": "Passer le site en français",
    "en-flag-alt": "English flag",
    "en-flag-disabled-alt": "English flag disabled",
    "en-flag-title": "Passer le site en anglais"
  },
  "footer": {
    "made-by": "Made by ",
    "legal-mentions": "Legal mentions"
  },
  "modal-refresh": {
    "title": "New version",
    "details": "A new version is available",
    "question": "Do you want to reload page now ?",
    "close": "Close",
    "refresh": "Reload"
  },
  "about": {
    "title": "About me",
    "download-resume": "Download my resume",
    "publications-title": "My publications",
    "publications-authors": "Authors",
    "experiences-title": "My professional experiences",
    "experiences": {
      "months": "months",
      "year": "year",
      "bio-rad": {
        "title": "R&D Technician Bio-Rad",
        "place": "Steenvoorde",
        "description-1": "Carry out immunological tests on automated systems",
        "description-2": "Make diluents and buffers",
        "description-3": "Carry out the maintenance of the automatons",
        "description-4": "Monitor stocks of products and consumables",
        "description-5": "Prepare the results entry masks"
      },
      "inserm": {
        "title": "Assistant engineer LilnCog",
        "place": "Lille",
        "description-1": "Perform primary cultures of murine embryonic cortex / hippocampi in a microfluidic system",
        "description-2": "Maintain «GMO type II»",
        "description-3": "Carry out electrophysiological studies of neurons",
        "description-4": "Study the biochemical properties of proteins",
        "description-5": "Perform immunofluorescence"
      }
    },
    "trainings-title": "My trainings",
    "trainings": {
      "bac": {
        "title": "Technological Baccalaureate (french equivalent of A level)",
        "speciality": "Laboratory science and technology - Biotechnology specialty"
      },
      "bts": {
        "title": "2nd year technician degree"
      },
      "licence": {
        "title": "Professional License TIB",
        "speciality": "Therapeutic Innovation and Biotechnologies"
      },
      "experiment": {
        "title": "Animal experimentation",
        "speciality": "Université de Lille"
      },
      "master": {
        "title": "Master in Health Engineering",
        "speciality": "Healthcare Business"
      }
    },
    "internships-title": "My internships",
    "internships": {
      "bts": {
        "title": "2nd year technician degree internship",
        "place": "PRISM laboratory, INSERM U1192, Villeneuve d'Ascq",
        "tutor": "Pr. Christophe Lefebvre",
        "second-title-year": "Second year",
        "second-title": "Development of a technique for enriching extracellular vesicles of human neuroblastoma",
        "description-second-1": "Develop an exclusion chromatography",
        "description-second-2": "Maintaining a cell line",
        "description-second-3": "Analyze extracellular vesicle samples",
        "description-second-4": "Analyze and criticize data",
        "first-title-year": "First year",
        "first-title": "Study of the microglia-neuron dialogue",
        "description-first-1": "Follow a primary culture of neuron and microglia",
        "description-first-2": "Counting neurites using software (imageJ)",
        "description-first-3": "Enrich extracellular vesicles",
        "description-first-4": "Follow a study on miRNAs"
      },
      "licence": {
        "title": "Professional License TIB internship",
        "place": "LIRIC laboratory, UMR995/eq 2, Lille",
        "tutor": "Mr. Samir Jawhara",
        "first-title": "Study of the anti-inflammatory potential of the H89 molecule and search for volatile markers in a mouse model of colitis chemo-induced by Sodium Dextran Sulfate",
        "description-1": "Follow an animal experiment",
        "description-2": "Perform bacteria and yeast cultures",
        "description-3": "Make histological sections",
        "description-4": "Look for biological markers",
        "description-5": "Studying cellular signals"
      }
    }
  },
  "contact": {
    "title": "Contact me",
    "personal-info": "Personal details",
    "linkedin": "LinkedIn Profile",
    "form": "Contact form",
    "form-name": "Name",
    "form-name-placeholder": "Enter your name",
    "form-email": "Email",
    "form-email-placeholder": "Enter your email address",
    "form-message": "Message",
    "form-message-placeholder": "Enter your message",
    "form-send": "Send",
    "form-required": "This field is required",
    "form-incorrect-mail": "Email address you entered is incorrect",
    "message-sent": "Your message has been sent."
  },
  "back": "Go back to home page",
  "construction": "This page is currently under construction"
}
{
  "navbar": {
    "about": "A propos",
    "contact": "Me contacter"
  },
  "home": {
    "hello": "Bonjour, je suis",
    "job-assistant": "Assistant ingénieur",
    "job-technician": "Technicien de laboratoire",
    "desired-job-intro": "Je recherche un stage du 28 mars au 12 août 2022 dans le domaine du",
    "desired-job": "Business Development"
  },
  "header": {
    "fr-flag-alt": "Drapeau français",
    "fr-flag-disabled-alt": "Drapeau français désactivé",
    "fr-flag-title": "Set site language to french",
    "en-flag-alt": "Drapeau anglais",
    "en-flag-disabled-alt": "Drapeau anglais désactivé",
    "en-flag-title": "Set site language to english"
  },
  "footer": {
    "made-by": "Réalisé par ",
    "legal-mentions": "Mentions légales"
  },
  "modal-refresh": {
    "title": "Nouvelle version",
    "details": "Une nouvelle version est disponible",
    "question": "Voulez-vous recharger la page maintenant ?",
    "close": "Fermer",
    "refresh": "Recharger"
  },
  "about": {
    "title": "A propos",
    "download-resume": "Télécharger mon CV",
    "publications-title": "Mes publications",
    "publications-authors": "Auteurs",
    "experiences-title": "Mes expériences professionnelles",
    "experiences": {
      "months": "mois",
      "year": "an",
      "bio-rad": {
        "title": "Technicien R&D Bio-Rad",
        "place": "Steenvoorde",
        "description-1": "Réaliser des tests immunologiques sur des automates",
        "description-2": "Fabriquer des diluants et tampons",
        "description-3": "Réaliser les maintenances sur les automates",
        "description-4": "Suivre les stocks de produits et de consommables",
        "description-5": "Préparer les masques de saisies des résultats"
      },
      "inserm": {
        "title": "Assistant ingénieur UMR 1167/1172",
        "place": "Lille",
        "description-1": "Réaliser des cultures primaires de cortex / hippocampes embryonnaires murins en système micro fluidiques",
        "description-2": "Entretenir des clones cellulaires «OGM de type II»",
        "description-3": "Réaliser des études électrophysiologiques de neurones",
        "description-4": "Etudier les propriétés biochimiques de protéines",
        "description-5": "Réaliser des immunofluorescences"
      }
    },
    "trainings-title": "Mes formations",
    "trainings": {
      "bac": {
        "title": "Baccalauréat Technologique",
        "speciality": "Sciences et technologies de laboratoire - spécialité Biotechnologies"
      },
      "bts": {
        "title": "BTS Biotechnologies"
      },
      "licence": {
        "title": "Licence Professionnelle ITB",
        "speciality": "Innovation Thérapeutique et Biotechnologies"
      },
      "experiment": {
        "title": "Expérimentation animale",
        "speciality": "Université de Lille"
      },
      "master": {
        "title": "Master Ingénierie de la santé",
        "speciality": "Healthcare Business"
      }
    },
    "internships-title": "Mes stages",
    "internships": {
      "bts": {
        "title": "Stage de BTS Biotechnologies",
        "place": "Laboratoire PRISM, INSERM U1192, Villeneuve d'Ascq",
        "tutor": "Pr. Christophe Lefebvre",
        "second-title-year": "Deuxième année",
        "second-title": "Mise au point d'une technique d'enrichissement de vésicules extracellulaire de neuroblastome humain",
        "description-second-1": "Mettre au point une chromatographie d'exclusion",
        "description-second-2": "Entretenir une lignée cellulaire",
        "description-second-3": "Analyser des échantillons de vésicules extracellulaires",
        "description-second-4": "Analyser et critiquer des données",
        "first-title-year": "Première année",
        "first-title": "Etude du dialogue microglie-neurone",
        "description-first-1": "Suivre une culture primaire de neurone et de microglie",
        "description-first-2": "Compter des neurites à l'aide d'un logiciel",
        "description-first-3": "Enrichir des vésicules extracellulaires",
        "description-first-4": "Suivre une étude sur les miARNs"
      },
      "licence": {
        "title": "Stage de licence professionnelle ITB",
        "place": "Laboratoire LIRIC, UMR995/eq 2, Lille",
        "tutor": "M. Samir Jawhara",
        "first-title": "Etude du potentiel anti-inflammatoire de la molécule H89 et recherche de marqueurs volatils dans un modèle murin de colite chimio-induite par Dextran Sulfate de Sodium",
        "description-1": "Suivre une expérimentation animale",
        "description-2": "Réaliser des cultures de bactéries et de levures",
        "description-3": "Réaliser des coupes histologiques",
        "description-4": "Rechercher des marqueurs biologiques",
        "description-5": "Etudier des signalisations cellulaires"
      }
    }
  },
  "contact": {
    "title": "Me contacter",
    "personal-info": "Informations personnelles",
    "linkedin": "Profil LinkedIn",
    "form": "Formulaire de contact",
    "form-name": "Nom",
    "form-name-placeholder": "Entrez votre nom",
    "form-email": "Email",
    "form-email-placeholder": "Entrez votre adresse mail",
    "form-message": "Message",
    "form-message-placeholder": "Entrez votre message",
    "form-send": "Envoyer",
    "form-required": "Ce champs est obligatoire",
    "form-incorrect-mail": "L'adresse mail fournie est incorrecte",
    "message-sent": "Votre message a bien été envoyé."
  },
  "back": "Revenir à l'accueil",
  "construction": "Cette page est en cours de construction"
}
<template>
  <header>
    <img v-if="locale === 'fr'" src="@/assets/images/flags/fr.png" :alt="$t('header.fr-flag-alt')">
    <img v-else src="@/assets/images/flags/fr-disabled.png" 
      :alt="$t('header.fr-flag-disabled-alt')" 
      @click="updateLocale('fr')" 
      class="header-img-disabled"
      :title="$t('header.fr-flag-title')">

    <img v-if="locale === 'en'" src="@/assets/images/flags/en.png" :alt="$t('header.en-flag-alt')">
    <img v-else src="@/assets/images/flags/en-disabled.png"
      :alt="$t('header.en-flag-disabled-alt')"
      @click="updateLocale('en')"
      class="header-img-disabled"
      :title="$t('header.en-flag-title')">
  </header>
  <div class="app">
    <div class="app-col app-col-left">
      <img src="@/assets/images/corentin.jpg" alt="Profile picture">
    </div>
    <div class="app-col app-col-right">
      <router-view v-slot="{ Component }">
        <transition name="router-slide" mode="out-in" appear>
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
  <footer>
    {{ $t("footer.made-by") }} <a href="https://dumortierweb.fr" target="blank">Julien Dumortier</a>
    <span class="footer-legal-mentions">- <a href="./documents/legal_mentions.pdf" target="blank">{{ $t('footer.legal-mentions') }}</a></span>
  </footer>
  <modal-refresh v-if="hasNewContent" @close="hasNewContent = false" @refresh="refreshApp" />
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Settings } from 'luxon'

export default {
  setup() {
    /* --------------- Init app ---------------- */
    const hasNewContent = ref(false)
    const refreshing = ref(false)
    const newRegistration = ref(null)

    const showRefreshUI = e => {
      newRegistration.value = e.detail
      hasNewContent.value = true
    }

    const refreshApp = () => {
      hasNewContent.value = false
      newRegistration.value?.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    document.addEventListener('swUpdated', showRefreshUI, { once: false })
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing.value) return
        //eslint-disable-next-line no-console
        console.info('[Service worker] refreshing app')
        refreshing.value = true
        window.location.reload()
      })
    }
    /* ------------ End of init app ------------ */

    let locale = ref(useI18n({ useScope: 'global' }).locale)

    const updateLocale = newLocale => {
      locale.value = newLocale
      Settings.defaultLocale = newLocale
      
    }

    return { hasNewContent, refreshApp, locale, updateLocale }
  }
}
</script>

<style lang="scss">
  header {
    height: 2rem;
    width: 100%;
    background-color: var(--main-color);
    color: var(--contrast-color);
    line-height: 2rem;
    text-align: right;
    padding-right: 1rem;
    box-sizing: border-box;

    img {
      height: 50%;
      margin-left: 1rem;

      &.header-img-disabled {
        cursor: pointer;
      }
    }
  }
  .app {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 4rem);

    &-col {
      height: 100%;
      background-color: var(--main-color);

      &-left {
        width: 35%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border: 1rem solid var(--main-color);
          box-sizing: border-box;
        }
      }

      &-right {
        width: 65%;
        overflow: hidden;

        .router-slide-enter-from {
          opacity: 0;
          transform: translateX(100px);
        }

        .router-slide-enter-active {
          transition: all 0.3s ease-out;
        }

        .router-slide-leave-to {
          opacity: 0;
          transform: translateX(-100px);
        }

        .router-slide-leave-active {
          transition: all 0.3s ease-in;
        }
      }
    }
  }
  footer {
    text-align: center;
    background-color: var(--main-color);
    color: var(--contrast-color);
    height: 2rem;
    width: 100%;

    a, a:visited, a:hover, a:active, a:focus, a:target {
      color: var(--contrast-color);
    }

    .footer-legal-mentions {
      margin-left: 0.2rem;
    }
  }

  @include desktop {
    .app {
      flex-direction: column;
      height: auto;
      background-color: var(--main-color);

      &-col {
        height: auto;

        &-left {
          width: 100%;
          text-align: center;

          img {
            width: 30%;
          }
        }
        &-right {
          width: 100%;
        }
      }
    }

    footer {
      font-size: 2rem;
      height: auto;
      padding: 1rem;
      box-sizing: border-box;
    }
  }
  @include mobile {
    .app { &-col { &-left { img { width: 40%; } } } }
    footer {
      font-size: 1.5rem;
    }
  }
  @include sm-mobile {
    .app { &-col { &-left { img { width: 60%; } } } }
  }
</style>

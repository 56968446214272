<template>
  <div class="modal-refresh backdrop" @click.self="$emit('close')">
    <div class="modal pop">
      <div class="title">{{ $t('modal-refresh.title') }}</div>
      <div class="content">
        {{ $t('modal-refresh.details') }} <br />
        {{ $t('modal-refresh.question') }}
      </div>
      <div class="actions">
        <button class="close button" @click="$emit('close')">{{ $t('modal-refresh.close') }}</button>
        <button class="refresh button" @click="$emit('refresh')">{{ $t('modal-refresh.refresh') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalRefresh',
    emits: ['close', 'refresh']
  }
</script>

<style lang="scss" scoped>
  .modal-refresh {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1rem;
    z-index: 500;
    backdrop-filter: blur(12px);
    background-color: rgba(0, 0, 0, 0.2);
    padding-right: 1rem;
    padding-bottom: 1rem;

    .modal {
      width: 40%;
      color: black;
      background: white;
      border-radius: 2rem;
      padding: 2rem;
      box-shadow: 0 4px 1rem rgba(0, 0, 0, 0.2);
      @include desktop {
        width: 100%;
        max-width: 500px;
      }
      .title {
        font-size: 25px;
        font-weight: 700;
      }
      .content {
        margin: 1rem 0;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        .button {
          border: none;
          background: none;
          font-size: inherit;
          margin-left: 8px;
          outline: none;
          border-radius: 8px;
          padding: 0.5rem 1rem;
          &.refresh {
            background: var(--main-color);
            box-shadow: 0 2px 8px var(--main-color);
            color: white;
            font-weight: bold;
          }
          &.close {
            color: grey;
          }
        }
      }
    }
  }
</style>
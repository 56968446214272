import "./registerServiceWorker"
import InjectBaseComponents from "@/components/base"
import i18n from './i18n'

import { createApp as createVueApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { Settings } from 'luxon'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)

Settings.defaultLocale = navigator.language

export function createApp() {
  const app = createVueApp(App)
    .use(router)
    .use(i18n)
    .component('font-awesome-icon', FontAwesomeIcon)

  InjectBaseComponents(app)
  app.mount("#app")
}